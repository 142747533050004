<template>
  <div class="v-pb-xs">
    <section v-if="pageStore.ActionsOnMain">
      <div class="v-container">
        <div
          v-if="pageStore.Actions.state === 'success'"
          id="v-banners-main-page__MinasTirith"
        >
          <arora-swiper-slider
            :items="pageStore.ActionsOnMain"
            :max-items="isExtraSmall ? 2.1 : 5"
            :min-items="isExtraSmall ? 2.1 : 1"
            :space-between="20"
            pagination-type="dynamic-bullets"
          >
            <template #indexedItem="{ item, index }">
              <div
                class="v-banner-stories v-pointer"
                :class="{
                  'v-banner-stories__seen': pageStore.StoriesSeen.get(item.ID),
                  disabled: disabled
                }"
                @click="async () => await storiesClick(index)"
              >
                <arora-nuxt-image
                  disable-lazy
                  :alt="item.Title"
                  :image="item.ImageSmallEventInfo"
                  image-type="EventSmall"
                />
              </div>
            </template>
          </arora-swiper-slider>
        </div>
        <common-skeleton
          v-else
          :height="225"
          :loaders-in-row="5"
          :space-between="20"
          :width="225"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { type GUID, useWindowSize } from '@arora/common'

const pageStore = usePageStore()
const popupStore = usePopupStore()

const seenStories = useCookie('seenStories')
const { isExtraSmall } = useWindowSize()

const disabled = ref<boolean>(false)

async function storiesClick(index: number): Promise<void> {
  disabled.value = true
  await popupStore.openPopup({
    popupName: 'bannerStories',
    popupClosable: true,
    popupProperties: new Map<string, unknown>([['index', index]]),
    onClosePopup: () => (disabled.value = false)
  })
}

onBeforeMount(() => {
  for (const seen of seenStories.value?.split(',') ?? []) {
    pageStore.markStoryAsSeen(seen as GUID)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

#v-banners-main-page__MinasTirith {
  .v-swiper {
    .swiper-wrapper {
      padding-bottom: 30px;
    }
  }

  .swiper-pagination-bullets {
    bottom: -12px;
    .swiper-pagination-bullet-active {
      background: variables.$SecondaryBackgroundColor;
    }
  }

  .swiper-slide {
    width: calc(16.66666667% - 5px); //as two columns
    height: auto;

    @include mixins.md {
      width: calc(25% - 5px);
    }

    @include mixins.sm {
      width: calc(33.3333333333% - 5px);
    }
  }

  .v-banner-stories {
    width: 100%;
    height: 100%;
    padding: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid variables.$SecondaryBackgroundColor;
    border-radius: variables.$BorderRadius;

    &__seen {
      border-color: variables.$SecondaryBackgroundColorOpaq50;

      .v-img-fluid {
        box-shadow: none;
      }
    }

    .v-img-fluid {
      box-shadow: variables.$BannerShadow;
      border-radius: calc(variables.$BorderRadius - 5px);
      overflow: hidden;
    }
  }
}
</style>
